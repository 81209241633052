<template>
  <MasterDetail
    :cols="cols"
    :resourceUrl="resourceUrl"
    :opts="{}"
  ></MasterDetail>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    MasterDetail: () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    cols: function () {
      return [
        {
          key: "id",
          name: "ID",
          hideInform: true,
        },
        {
          key: "nome",
          name: "Nome do Grupo",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }, { rule: "min", params: { size: 3 }}],
        },
        {
          key: "modificado_em",
          name: "Modificado em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "criado_em",
          name: "Criado em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ];
    },
    resourceUrl: function () {
      return `v1/clientes/${this.clientId}/corporacao`;
    },
  },
}
</script>
